import one from "../../Assets/products/one.jpeg";
import two from "../../Assets/products/two.jpeg";
import three from "../../Assets/products/three.jpeg";
import four from "../../Assets/products/four.jpeg";

const services_Data = [
  {
    id: 1,
    image: one,
    name: "Tomato Flavour",
    discription:
      "Peanuts, Wheat flour, Iodised Salt, Coconut milk, Tomato Masala & edible oil",
  },
  {
    id: 2,
    image: two,
    name: "Hot & Spicy",
    discription:
      "Peanuts, Wheat flour, Iodised Salt, Coconut milk, Spicy Masala & edible oil",
  },
  {
    id: 3,
    image: three,
    name: "Chat Pata Flavour",
    discription:
      "Peanuts, Wheat flour, Iodised Salt Coconut milk, Chat Masala & edible oil",
  },
  {
    id: 4,
    image: four,
    name: "Cocont milk",
    discription:
      "Peanuts, Wheat flour, Coconut milk, sugar, Iodised salt Masala & edible oil",
  },
];
export default services_Data;
